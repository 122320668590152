var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", staticStyle: { position: "relative" } },
    [
      _c("div", { staticClass: "edit-format-bg" }, [
        _c("img", { attrs: { src: _vm.imgUrl + _vm.openJson.logo } }),
      ]),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("screenfull"),
          _vm.rand.type === 1
            ? [
                _vm.rand.type_group === 1
                  ? [
                      _c("div", { staticClass: "title-group" }, [
                        _vm._v(_vm._s(_vm.rand.name)),
                      ]),
                      _c("div", { staticClass: "list-group" }, [
                        _c("div", { staticClass: "list-title" }, [
                          _vm._v("摇号组"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "list-box" },
                          _vm._l(_vm.group, function (item, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass: "list flex space-between",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "list-name",
                                    staticStyle: { "white-space": "pre-wrap" },
                                  },
                                  [_vm._v(_vm._s(item.group_name))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "list-text",
                                    staticStyle: { "white-space": "pre-wrap" },
                                  },
                                  [_vm._v(_vm._s(item.status_name))]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  : [
                      _vm.openJson.carShow
                        ? _c(
                            "div",
                            {
                              staticClass: "title",
                              style:
                                "white-space: pre-wrap;" +
                                "font-size:" +
                                _vm.openJson.car_fontSize +
                                "px;" +
                                "height:" +
                                _vm.openJson.carHeight +
                                "px;" +
                                "top:" +
                                _vm.openJson.card_margin +
                                "px;" +
                                "background: " +
                                (_vm.openJson.card_background_color == 1
                                  ? "rgba(255, 255, 255, 0.1);"
                                  : "rgba(255, 255, 255, 0);"),
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.openJson.car_name || _vm.rand.name)
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
              ]
            : [
                _vm.rand.type_group === 1
                  ? [
                      _c("div", { staticClass: "title-group" }, [
                        _vm._v(_vm._s(_vm.openJson.car_name || _vm.rand.name)),
                      ]),
                      _c("div", { staticClass: "list-group" }, [
                        _c("div", { staticClass: "list-title" }, [
                          _vm._v("摇号组"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "list-box" },
                          _vm._l(_vm.group, function (item, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass: "list flex space-between",
                              },
                              [
                                _c("div", { staticClass: "list-name" }, [
                                  _vm._v(_vm._s(item.group_name)),
                                ]),
                                _c("div", { staticClass: "list-text" }, [
                                  _vm._v(_vm._s(item.status_name)),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  : [
                      _vm.openJson.carShow
                        ? _c(
                            "div",
                            {
                              staticClass: "title",
                              style:
                                "white-space: pre-wrap;" +
                                "font-size:" +
                                _vm.openJson.car_fontSize +
                                "px;" +
                                "height:" +
                                _vm.openJson.carHeight +
                                "px;" +
                                "top:" +
                                _vm.openJson.card_margin +
                                "px;" +
                                "background: " +
                                (_vm.openJson.card_background_color == 1
                                  ? "rgba(255, 255, 255, 0.1);"
                                  : "rgba(255, 255, 255, 0);"),
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.openJson.car_name || _vm.rand.name)
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
              ],
          _c(
            "el-button",
            {
              staticClass: "button-size",
              style: "top:" + _vm.openJson.btn_position + "px",
              attrs: { size: "medium" },
              on: { click: _vm.handleStartLottery },
            },
            [_vm._v(" " + _vm._s(_vm.list.start_name) + " ")]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }