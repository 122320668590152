var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-item" }, [
    _c("div", { staticClass: "list-item-header" }, [
      _c(
        "div",
        { staticClass: "list-item-header-left" },
        [
          _c(
            "el-tooltip",
            {
              attrs: {
                manual: true,
                content:
                  "当前状态修改摇号规则对此活动无效！若需修改请重置摇号！",
                placement: "top-start",
              },
              model: {
                value: _vm.showHintStaus,
                callback: function ($$v) {
                  _vm.showHintStaus = $$v
                },
                expression: "showHintStaus",
              },
            },
            [
              _vm.row.log_rand_cound == 0 ||
              _vm.row.user_count > _vm.row.log_rand_cound
                ? _c(
                    "div",
                    {
                      class: [
                        "header-left-status",
                        { "header-left-status-active": _vm.row.is_start == 2 },
                      ],
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.row.is_start == 2 ? "摇号中" : "待摇号") +
                          " "
                      ),
                    ]
                  )
                : _c("div", { staticClass: "header-left-status" }, [
                    _vm._v("摇号结束"),
                  ]),
            ]
          ),
          _c("div", { staticClass: "header-left-name" }, [
            _vm._v(_vm._s(_vm.row.event_name)),
          ]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "list-item-box" }, [
      _c("div", { staticClass: "item-content" }, [
        _c("div", { staticClass: "content-left content-text" }, [
          _c(
            "span",
            {
              staticClass: "content-text",
              staticStyle: { "white-space": "pre-wrap" },
            },
            [_vm._v("摇号名称: " + _vm._s(_vm.row.name))]
          ),
        ]),
        _c("div", { staticClass: "content-right content-text" }, [
          _c("span", { staticClass: "content-text" }, [
            _vm._v(
              "轮次人数: " +
                _vm._s(_vm.row.type === 1 ? "随机摇号" : "按序排号") +
                "-" +
                _vm._s(_vm.row.rand_count) +
                "人每轮"
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "item-content" }, [
        _c("div", { staticClass: "content-left" }, [
          _c("span", { staticClass: "content-text" }, [
            _vm._v(" 已摇号: "),
            _c("span", [_vm._v(_vm._s(_vm.row.log_group_number) + "轮")]),
            _c("span", [_vm._v("- " + _vm._s(_vm.row.log_rand_cound) + "人")]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "eventrand/randlist"],
                  expression: "['admin', 'eventrand/randlist']",
                },
              ],
              staticClass: "btn",
              on: {
                click: function ($event) {
                  return _vm.$emit("handleRecord", _vm.row.id)
                },
              },
            },
            [_vm._v("摇号记录")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "eventrand/print"],
                  expression: "['admin', 'eventrand/print']",
                },
              ],
              staticClass: "btn",
              staticStyle: { "margin-left": "10px" },
              on: {
                click: function ($event) {
                  return _vm.$emit("handlePrintUp", _vm.row.id)
                },
              },
            },
            [_vm._v("打印")]
          ),
        ]),
        _c("div", { staticClass: "content-right" }, [
          _c("span", { staticClass: "content-text" }, [
            _vm._v(" 待摇号: "),
            _vm.row.type_group !== 1
              ? _c("span", [_vm._v(_vm._s(_vm.row.not_group_number) + "轮 - ")])
              : _vm._e(),
            _c("span", [_vm._v(_vm._s(_vm.row.not_rand_count) + "人")]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "item-content" }, [
        _c("div", { staticClass: "content-left" }, [
          _c("span", { staticClass: "content-text" }, [
            _vm._v(" 客户总数: "),
            _vm.row.type_rand_user === 1
              ? _c("span", [
                  _vm._v("全部客户 -（" + _vm._s(_vm.row.user_count) + "人）"),
                ])
              : _c("span", [
                  _vm._v("签到客户 -（" + _vm._s(_vm.row.user_count) + "人）"),
                ]),
          ]),
        ]),
        _c("div", { staticClass: "content-right" }, [
          _c("span", { staticClass: "content-text" }, [
            _vm._v(" 分组: "),
            _vm.row.type_group === 1
              ? _c("span", [_vm._v(_vm._s(_vm.row.level_count) + "组")])
              : _c("span", [_vm._v("无组")]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.row.type_group === 1,
                  expression: "row.type_group === 1",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "eventrand/selgroup"],
                  expression: "['admin', 'eventrand/selgroup']",
                },
              ],
              staticClass: "btn",
              on: {
                click: function ($event) {
                  return _vm.$emit("handleGrouping", _vm.row.id)
                },
              },
            },
            [_vm._v(" 查看分组 ")]
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "list-item-operation" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: ["admin", "eventrand/randindex"],
              expression: "['admin', 'eventrand/randindex']",
            },
          ],
          on: {
            click: function ($event) {
              return _vm.$emit("handleStartLottery", _vm.row)
            },
          },
        },
        [
          _c("img", {
            staticClass: "icon_image",
            attrs: { src: require("@/assets/icon/bofang.png") },
          }),
          _c("span", [_vm._v(_vm._s(_vm.getText(_vm.row)))]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: ["admin", "eventrand/entryindex"],
              expression: "['admin', 'eventrand/entryindex']",
            },
          ],
          on: {
            click: function ($event) {
              return _vm.$emit("lotteryEntryIndex", _vm.row)
            },
          },
        },
        [
          _c("img", {
            staticClass: "icon_image",
            attrs: { src: require("@/assets/icon/chouhao.png") },
          }),
          _c("span", [_vm._v("抽号匹配")]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: ["admin", "eventrand/randledstart"],
              expression: "['admin', 'eventrand/randledstart']",
            },
          ],
          on: {
            click: function ($event) {
              return _vm.$emit("lotterySplitScreen", _vm.row)
            },
          },
        },
        [
          _c("img", {
            staticClass: "icon_image",
            attrs: { src: require("@/assets/icon/fenping.png") },
          }),
          _c("span", [_vm._v("摇号分屏")]),
        ]
      ),
      _vm.row.is_led_screen === 1
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "eventrand/getleddata"],
                  expression: "['admin', 'eventrand/getleddata']",
                },
              ],
              on: {
                click: function ($event) {
                  return _vm.$emit("lotteryPublicity", _vm.row)
                },
              },
            },
            [
              _c("img", {
                staticClass: "icon_image",
                attrs: { src: require("@/assets/icon/daping.png") },
              }),
              _c("span", [_vm._v("公示大屏")]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: ["admin", "eventrand/reset"],
              expression: "['admin', 'eventrand/reset']",
            },
          ],
          on: {
            click: function ($event) {
              return _vm.$emit("handleReset", _vm.row.id)
            },
          },
        },
        [
          _c("img", {
            staticClass: "icon_image",
            attrs: { src: require("@/assets/icon/reset.png") },
          }),
          _c("span", [_vm._v("重置摇号")]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }