import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import screenfull from "@/components/my-screen/index.vue";
import { eventRandIndex } from "@/api/lottery";
export default {
  name: "openStyleDome",
  components: {
    screenfull: screenfull
  },
  props: {
    openJson: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      styleObject: {},
      // 基本数据
      rand: {},
      // 摇号组
      group: [],
      list: {}
    };
  },
  created: function created() {
    this.getDataIndex();
  },
  mounted: function mounted() {},
  methods: {
    //    获取主页 基础数据
    getDataIndex: function getDataIndex() {
      var _this = this;

      eventRandIndex({
        id: this.$route.query.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data$list = res.data.list,
              group = _res$data$list.group,
              rand = _res$data$list.rand,
              group_id = _res$data$list.group_id;
          rand.name = rand.name || '正式活动摇号';
          _this.rand = rand;
          _this.styleObject = {
            background: "url(".concat(_this.imgUrl + rand.logo, ")100%, no-repeat"),
            backgroundSize: '100%',
            // backgroundPosition: 'center, center',
            height: '100%'
          };
          _this.group = group;
          _this.group_id = group_id;
          _this.list = res.data.list; // 在缓存里加一个

          localStorage.setItem(parseInt(new Date().getTime() / 86400000).toString(), "".concat(rand.id, "-").concat(_this.list.event.type));
        }
      });
    }
  }
};