var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c("img", {
      staticClass: "background-img",
      attrs: { src: _vm.imgUrl + "rand/lottery.png" },
    }),
    _c("div", { staticClass: "box-title" }, [
      _vm._v(_vm._s(_vm.project_name) + " 叠墅区车位摇号"),
    ]),
    _c(
      "div",
      { staticClass: "box-content", staticStyle: { "margin-top": "80px" } },
      [
        _c(
          "div",
          { staticClass: "box-content-left" },
          [
            _c("div", { staticClass: "box-content-left-title" }, [
              _vm._v(
                " " + _vm._s(_vm.type === 2 ? "客户信息" : "查找客户") + " "
              ),
            ]),
            _vm.type === 0
              ? [
                  _c(
                    "div",
                    { staticClass: "box-content-left-seek" },
                    [_c("search-user", { attrs: { width: "240px" } })],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "box-content-left-desc-wrapper" },
                    [
                      _vm.socketLoading
                        ? [
                            _c("span", [
                              _vm._v("正在连接身份证识别器驱动程序，请稍候..."),
                            ]),
                          ]
                        : [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.socketConnect
                                    ? _vm.searchLoading
                                      ? "正在查找客户..."
                                      : "请将身份证放在读卡器上以查找客户"
                                    : "请检查设备驱动是否安装正常"
                                )
                              ),
                            ]),
                            !_vm.socketConnect
                              ? _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "box-content-left-download-text",
                                      attrs: { href: "" + _vm.driveUrl },
                                    },
                                    [_vm._v("下载身份证识别器驱动程序")]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        "margin-left": "35px",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "box-content-left-input",
                        attrs: {
                          placeholder: "输入客户的手机号，身份证，姓名查询",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getUserList()
                          },
                        },
                        model: {
                          value: _vm.searchParams.tel,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchParams, "tel", $$v)
                          },
                          expression: "searchParams.tel",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              : _vm.type === 1
              ? [
                  _c(
                    "div",
                    { staticClass: "box-content-left-userList" },
                    _vm._l(_vm.userList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          class: {
                            "box-content-left-userList-item": true,
                            "select-userList-item": item.checked,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onCheckedUser(index)
                            },
                          },
                        },
                        [
                          item.checked
                            ? _c("img", {
                                staticStyle: {
                                  width: "34px",
                                  height: "30px",
                                  position: "absolute",
                                  bottom: "0",
                                  right: "0",
                                },
                                attrs: {
                                  src: require("@/assets/image/randRoom-select.png"),
                                },
                              })
                            : _vm._e(),
                          _c("img", {
                            staticStyle: { width: "60px", height: "60px" },
                            attrs: {
                              src: require("@/assets/image/avatar_icon.png"),
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-left": "20px",
                                width: "200px",
                                color: "white",
                              },
                            },
                            [
                              item.name
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { "padding-bottom": "10px" },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-col", { attrs: { span: 9 } }, [
                                            _vm._v(" 姓名： "),
                                          ]),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 14 } },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.tel
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { "padding-bottom": "10px" },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-col", { attrs: { span: 9 } }, [
                                            _vm._v(" 手机号： "),
                                          ]),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 14 } },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.tel) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.card
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { "padding-bottom": "10px" },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-col", { attrs: { span: 9 } }, [
                                            _vm._v(" 身份证号： "),
                                          ]),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 14 } },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.card) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.code
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { "padding-bottom": "10px" },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-col", { attrs: { span: 9 } }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$adminList.user_code_name
                                                ) +
                                                "： "
                                            ),
                                          ]),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 14 } },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.code) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.level
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { "padding-bottom": "10px" },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-col", { attrs: { span: 9 } }, [
                                            _vm._v(" 对应楼栋： "),
                                          ]),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 14 } },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.level) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.admin_name
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { "padding-bottom": "10px" },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-col", { attrs: { span: 9 } }, [
                                            _vm._v(" 置业顾问： "),
                                          ]),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 14 } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.admin_name) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              : _vm.type === 2
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "box-content-left-userList",
                      staticStyle: { padding: "30px" },
                    },
                    [
                      _vm._m(0),
                      _vm.userInfo.name
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "20px",
                                color: "white",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 9 } }, [
                                    _vm._v(" 客户姓名： "),
                                  ]),
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { "text-align": "right" },
                                      attrs: { span: 15 },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.userInfo.name) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.userInfo.tel
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "20px",
                                color: "white",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 9 } }, [
                                    _vm._v(" 手机号： "),
                                  ]),
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { "text-align": "right" },
                                      attrs: { span: 15 },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.userInfo.tel) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.userInfo.card
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "20px",
                                color: "white",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 9 } }, [
                                    _vm._v(" 身份证号： "),
                                  ]),
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { "text-align": "right" },
                                      attrs: { span: 15 },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.userInfo.card) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.userInfo.code
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "20px",
                                color: "white",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 9 } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$adminList.user_code_name) +
                                        "： "
                                    ),
                                  ]),
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { "text-align": "right" },
                                      attrs: { span: 15 },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.userInfo.code) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.userInfo.level
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "20px",
                                color: "white",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 9 } }, [
                                    _vm._v(" 对应楼栋： "),
                                  ]),
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { "text-align": "right" },
                                      attrs: { span: 15 },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.userInfo.level) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.userInfo.admin_name
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "20px",
                                color: "white",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 9 } }, [
                                    _vm._v(" 置业顾问： "),
                                  ]),
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { "text-align": "right" },
                                      attrs: { span: 15 },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.userInfo.admin_name) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "box-content-left-bottom",
                on: { click: _vm.onLeftBottom },
              },
              [
                _vm.type === 0
                  ? _c("span", [_vm._v("查询")])
                  : _vm.type === 1
                  ? _c("span", [_vm._v("确认")])
                  : _vm.type === 2
                  ? _c("span", [_vm._v("换个客户")])
                  : _vm._e(),
              ]
            ),
          ],
          2
        ),
        _vm.type === 2
          ? _c("div", { staticClass: "box-content-right" }, [
              _c(
                "div",
                { staticClass: "box-content-right-room" },
                [
                  _vm.before_count &&
                  _vm.userInfo.buy_count > 1 &&
                  typeof _vm.roomName !== "object"
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            "font-size": "50px",
                            position: "absolute",
                            top: "calc(50% - 100px)",
                          },
                        },
                        [_vm._v(" 第" + _vm._s(_vm.before_count) + "套 ")]
                      )
                    : _vm._e(),
                  typeof _vm.roomName === "object"
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "overflow-y-noshow",
                            staticStyle: {
                              width: "100%",
                              height: "calc(100% - 60px)",
                              "margin-top": "10px",
                              display: "flex",
                              "align-content": "center",
                              "flex-wrap": "wrap",
                            },
                          },
                          _vm._l(_vm.roomName, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticStyle: {
                                  width: "100%",
                                  "margin-bottom": "10px",
                                },
                              },
                              [_vm._v(_vm._s(item.room))]
                            )
                          }),
                          0
                        ),
                      ]
                    : _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            position: "absolute",
                            top: "calc(50% - 22px)",
                          },
                        },
                        [_vm._v(_vm._s(_vm.roomName))]
                      ),
                ],
                2
              ),
              _vm.type === 2
                ? _c(
                    "div",
                    {
                      staticClass: "box-content-right-button",
                      on: { click: _vm.onRightButton },
                    },
                    [
                      _vm.buttonType === 0
                        ? _c("span", [_vm._v("开始")])
                        : _vm.buttonType === 1
                        ? _c("span", [_vm._v("下一个")])
                        : _vm.buttonType === 2
                        ? _c("span", [_vm._v("停止")])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "padding-bottom": "13px",
          "border-bottom": "1px dashed white",
          "text-align": "center",
        },
      },
      [
        _c("img", {
          staticStyle: { width: "80px", height: "80px" },
          attrs: { src: require("@/assets/image/avatar_icon.png") },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }