var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "top-fixed" }, [
      _c(
        "div",
        { staticClass: "title-top-box" },
        [
          _vm._m(0),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "eventrand/setallrule"],
                  expression: "['admin', 'eventrand/setallrule']",
                },
              ],
              staticClass: "greens-btn",
              attrs: { size: "small" },
              on: { click: _vm.handleSaveInfo },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "detail-box mT16" }, [
      _c("div", { staticClass: "block" }, [
        _c(
          "div",
          { staticClass: "from-box" },
          [
            _c(
              "el-form",
              { attrs: { "label-width": "150px" } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "摇号名称：" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "300px" },
                      model: {
                        value: _vm.rand.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.rand, "name", $$v)
                        },
                        expression: "rand.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "模式：" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: _vm.rand.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "type", $$v)
                              },
                              expression: "rand.type",
                            },
                          },
                          [_vm._v("随机摇号")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 2 },
                            on: { change: _vm.handleNumber },
                            model: {
                              value: _vm.rand.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "type", $$v)
                              },
                              expression: "rand.type",
                            },
                          },
                          [_vm._v("按序排号")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.rand.type === 2
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "排号方式：" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-radio",
                              {
                                staticStyle: { "margin-right": "0" },
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.rand.type_code,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.rand, "type_code", $$v)
                                  },
                                  expression: "rand.type_code",
                                },
                              },
                              [_vm._v(_vm._s(_vm.list.user_code_name))]
                            ),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content:
                                    _vm.list.user_code_name +
                                    "仅支持数字或字母排序，不支持一二三等中文排序！",
                                },
                              },
                              [_c("i", { staticClass: "el-icon-question" })]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 2 },
                                model: {
                                  value: _vm.rand.type_code,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.rand, "type_code", $$v)
                                  },
                                  expression: "rand.type_code",
                                },
                              },
                              [_vm._v("签到顺序")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 3 },
                                model: {
                                  value: _vm.rand.type_code,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.rand, "type_code", $$v)
                                  },
                                  expression: "rand.type_code",
                                },
                              },
                              [_vm._v("客户特殊排序")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.rand.type === 2
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "下轮排号提醒：" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.rand.is_last_round_show,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.rand,
                                      "is_last_round_show",
                                      $$v
                                    )
                                  },
                                  expression: "rand.is_last_round_show",
                                },
                              },
                              [_vm._v("不显示")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: _vm.rand.is_last_round_show,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.rand,
                                      "is_last_round_show",
                                      $$v
                                    )
                                  },
                                  expression: "rand.is_last_round_show",
                                },
                              },
                              [_vm._v("显示")]
                            ),
                            _vm.rand.is_last_round_show == 0
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#616161" } },
                                  [
                                    _vm._v(" 后 "),
                                    _c("el-input-number", {
                                      staticStyle: { width: "8em" },
                                      attrs: { min: 1, max: 10 },
                                      model: {
                                        value: _vm.rand.last_round_num,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.rand,
                                            "last_round_num",
                                            $$v
                                          )
                                        },
                                        expression: "rand.last_round_num",
                                      },
                                    }),
                                    _vm._v("轮 "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.rand.type === 2,
                        expression: "rand.type === 2",
                      },
                    ],
                    attrs: { label: "摇号随机效果：" },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 0 },
                            model: {
                              value: _vm.rand.row_rand_roll,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "row_rand_roll", $$v)
                              },
                              expression: "rand.row_rand_roll",
                            },
                          },
                          [_vm._v("不启用")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _vm._v(" `"),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: _vm.rand.row_rand_roll,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "row_rand_roll", $$v)
                              },
                              expression: "rand.row_rand_roll",
                            },
                          },
                          [_vm._v("启用")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "分组摇号：" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-radio",
                          {
                            staticStyle: { "margin-right": "0" },
                            attrs: { label: 1 },
                            model: {
                              value: _vm.rand.type_group,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "type_group", $$v)
                              },
                              expression: "rand.type_group",
                            },
                          },
                          [_vm._v("公开分组摇号")]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content:
                                "摇号大屏上会明确分组摇号及分组切换的操作",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-question" })]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-radio",
                          {
                            staticStyle: { "margin-right": "0" },
                            attrs: { label: 3 },
                            model: {
                              value: _vm.rand.type_group,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "type_group", $$v)
                              },
                              expression: "rand.type_group",
                            },
                          },
                          [_vm._v("隐秘分组摇号")]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content:
                                "摇号大屏上不会展示分组摇号内容，但会按设定的分组顺序摇号",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-question" })]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 2 },
                            model: {
                              value: _vm.rand.type_group,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "type_group", $$v)
                              },
                              expression: "rand.type_group",
                            },
                          },
                          [_vm._v("不分组")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.rand.type_group === 1
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "分组名称展示：" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: _vm.rand.group_name_show,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.rand, "group_name_show", $$v)
                                  },
                                  expression: "rand.group_name_show",
                                },
                              },
                              [_vm._v("显示")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.rand.group_name_show,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.rand, "group_name_show", $$v)
                                  },
                                  expression: "rand.group_name_show",
                                },
                              },
                              [_vm._v("不显示")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "待摇号人数：" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 0 },
                            model: {
                              value: _vm.rand.is_stay_user,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "is_stay_user", $$v)
                              },
                              expression: "rand.is_stay_user",
                            },
                          },
                          [_vm._v("显示")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: _vm.rand.is_stay_user,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "is_stay_user", $$v)
                              },
                              expression: "rand.is_stay_user",
                            },
                          },
                          [_vm._v("不显示")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.rand.type_group === 1 || _vm.rand.type_group === 3
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "等级组别顺序：" } },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              border: "",
                              "highlight-current-row": "",
                              size: "medium",
                              data: _vm.rand.level_sort,
                              height: 300,
                              "header-cell-style": { background: "#fafafa" },
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                type: "index",
                                label: "排序",
                                width: "80",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "level",
                                label: "摇号分组",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.level === ""
                                                ? "无分组"
                                                : scope.row.level
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1141317852
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "level_count",
                                label: "客户数",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "操作",
                                width: "120",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("svg-icon", {
                                          attrs: {
                                            "class-name": "greens",
                                            "icon-class": "move_up",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleTop(scope.$index)
                                            },
                                          },
                                        }),
                                        _c("svg-icon", {
                                          attrs: {
                                            "class-name": "greens",
                                            "icon-class": "move_down",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleBottom(
                                                scope.$index
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2422187559
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("el-form-item", { attrs: { label: "轮次人数：" } }, [
                  _c(
                    "div",
                    { staticClass: "number" },
                    [
                      _c("span", [_vm._v("每轮")]),
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { type: "number", placeholder: "请输入" },
                        model: {
                          value: _vm.rand.rand_count,
                          callback: function ($$v) {
                            _vm.$set(_vm.rand, "rand_count", $$v)
                          },
                          expression: "rand.rand_count",
                        },
                      }),
                      _c("span", [_vm._v("人")]),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { label: "摇号轮次控制：" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 0 },
                            model: {
                              value: _vm.rand.is_round_control,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "is_round_control", $$v)
                              },
                              expression: "rand.is_round_control",
                            },
                          },
                          [_vm._v("不限制")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 20 } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: _vm.rand.is_round_control,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "is_round_control", $$v)
                              },
                              expression: "rand.is_round_control",
                            },
                          },
                          [_vm._v("限制")]
                        ),
                        _vm.rand.is_round_control == 1
                          ? _c(
                              "span",
                              { staticStyle: { color: "#616161" } },
                              [
                                _vm._v(" 仅摇出 "),
                                _c("el-input-number", {
                                  staticStyle: { width: "8em" },
                                  attrs: { min: 1, max: 10 },
                                  model: {
                                    value: _vm.rand.round_control_num,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.rand,
                                        "round_control_num",
                                        $$v
                                      )
                                    },
                                    expression: "rand.round_control_num",
                                  },
                                }),
                                _vm._v("轮 "),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "jumpPeon", attrs: { label: "到号显示：" } },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "input-box input-name",
                        attrs: { span: 24 },
                      },
                      [_vm._v("1.显示元素")]
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 20 } },
                      [
                        _c(
                          "el-row",
                          { staticClass: "mT15", attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 20, offset: 1 } },
                              [
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              "true-label": 1,
                                              "false-label": 0,
                                            },
                                            model: {
                                              value: _vm.rand.show_rand_code,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.rand,
                                                  "show_rand_code",
                                                  $$v
                                                )
                                              },
                                              expression: "rand.show_rand_code",
                                            },
                                          },
                                          [_vm._v(" 顺序号 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              "true-label": 1,
                                              "false-label": 0,
                                            },
                                            model: {
                                              value: _vm.rand.show_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.rand,
                                                  "show_name",
                                                  $$v
                                                )
                                              },
                                              expression: "rand.show_name",
                                            },
                                          },
                                          [_vm._v(" 姓名(脱敏) ")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              "true-label": 2,
                                              "false-label": 0,
                                            },
                                            model: {
                                              value: _vm.rand.show_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.rand,
                                                  "show_name",
                                                  $$v
                                                )
                                              },
                                              expression: "rand.show_name",
                                            },
                                          },
                                          [_vm._v(" 姓名(全显) ")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              "true-label": 1,
                                              "false-label": 0,
                                            },
                                            model: {
                                              value: _vm.rand.show_tel,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.rand,
                                                  "show_tel",
                                                  $$v
                                                )
                                              },
                                              expression: "rand.show_tel",
                                            },
                                          },
                                          [_vm._v(" 登录手机号 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              "true-label": 1,
                                              "false-label": 0,
                                            },
                                            model: {
                                              value: _vm.rand.show_audit_order,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.rand,
                                                  "show_audit_order",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "rand.show_audit_order",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$adminList.user_code_name
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              "true-label": 1,
                                              "false-label": 0,
                                            },
                                            model: {
                                              value: _vm.rand.show_card,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.rand,
                                                  "show_card",
                                                  $$v
                                                )
                                              },
                                              expression: "rand.show_card",
                                            },
                                          },
                                          [_vm._v(" 身份证号全部(脱敏) ")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              "true-label": 2,
                                              "false-label": 0,
                                            },
                                            model: {
                                              value: _vm.rand.show_card,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.rand,
                                                  "show_card",
                                                  $$v
                                                )
                                              },
                                              expression: "rand.show_card",
                                            },
                                          },
                                          [_vm._v(" 身份证号后6位 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              "true-label": 1,
                                              "false-label": 0,
                                            },
                                            model: {
                                              value: _vm.rand.show_other_remark,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.rand,
                                                  "show_other_remark",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "rand.show_other_remark",
                                            },
                                          },
                                          [_vm._v(" 其他信息 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "input-box input-name",
                        attrs: { span: 24 },
                      },
                      [_vm._v("2.顺序号生成规则")]
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 20 } },
                      [
                        _c(
                          "el-row",
                          { staticClass: "mT15", attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { staticClass: "input-box", attrs: { span: 20 } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "input-name",
                                    attrs: { span: 1 },
                                  },
                                  [_vm._v("轮：")]
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 23 } },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        staticStyle: { width: "100%" },
                                        on: { change: _vm.handleRound },
                                        model: {
                                          value: _vm.rand.type_round_code,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.rand,
                                              "type_round_code",
                                              $$v
                                            )
                                          },
                                          expression: "rand.type_round_code",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-row",
                                          { attrs: { gutter: 20 } },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: 1 } },
                                                  [_vm._v("号码继承上一轮")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: 0 } },
                                                  [_vm._v("不继承上一轮")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.rand.type === 1 && _vm.rand.type_group === 1
                          ? _c(
                              "el-row",
                              { staticClass: "mT15", attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "input-box",
                                    attrs: { span: 20 },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "input-name",
                                        attrs: { span: 1 },
                                      },
                                      [_vm._v("组：")]
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 23 } },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            staticStyle: { width: "100%" },
                                            model: {
                                              value: _vm.rand.type_group_code,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.rand,
                                                  "type_group_code",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "rand.type_group_code",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-row",
                                              { attrs: { gutter: 20 } },
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 8 } },
                                                  [
                                                    _c(
                                                      "el-radio",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.disabled,
                                                          label: 1,
                                                        },
                                                      },
                                                      [_vm._v("号码继承上一组")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 8 } },
                                                  [
                                                    _c(
                                                      "el-radio",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.disabled,
                                                          label: 0,
                                                        },
                                                      },
                                                      [_vm._v("不继承上一组")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "input-box input-box input-name",
                        attrs: { span: 24 },
                      },
                      [_vm._v("3.顺序号样式")]
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 20 } },
                      [
                        _c(
                          "el-row",
                          { staticClass: "mT15", attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { staticClass: "input-box", attrs: { span: 20 } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "input-name",
                                    attrs: { span: 1 },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { opacity: "0" } },
                                      [_vm._v("0")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 23 } },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        staticStyle: { width: "100%" },
                                        model: {
                                          value: _vm.rand.code_style,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.rand,
                                              "code_style",
                                              $$v
                                            )
                                          },
                                          expression: "rand.code_style",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-row",
                                          { attrs: { gutter: 20 } },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 10 } },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: 0 } },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width:
                                                          "calc(5rem + 35px)",
                                                      },
                                                      attrs: {
                                                        size: "small",
                                                        maxlength: "5",
                                                        "show-word-limit": "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.rand
                                                            .code_start_name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.rand,
                                                            "code_start_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "rand.code_start_name",
                                                      },
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "input-name",
                                                        staticStyle: {
                                                          padding: "0 10px",
                                                        },
                                                      },
                                                      [_vm._v(" X")]
                                                    ),
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width:
                                                          "calc(5rem + 35px)",
                                                      },
                                                      attrs: {
                                                        size: "small",
                                                        maxlength: "5",
                                                        "show-word-limit": "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.rand.code_unit,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.rand,
                                                            "code_unit",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "rand.code_unit",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-radio",
                                                  {
                                                    staticStyle: {
                                                      "line-height": "32px",
                                                    },
                                                    attrs: { label: 1 },
                                                  },
                                                  [
                                                    _vm._v("ABCD样式 "),
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          content:
                                                            "此样式仅在“选房摇号”模块做展示作用，其它模块还是第X号样式。",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-question",
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "摇号客户：" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticStyle: { width: "100%" },
                        model: {
                          value: _vm.rand.type_rand_user,
                          callback: function ($$v) {
                            _vm.$set(_vm.rand, "type_rand_user", $$v)
                          },
                          expression: "rand.type_rand_user",
                        },
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("全部客户"),
                            ]),
                          ],
                          1
                        ),
                        _vm.list.sign_in === 1
                          ? [
                              _vm.list.sign_type === 1
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c("el-radio", { attrs: { label: 2 } }, [
                                        _vm._v("签到客户"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "摇号屏+副屏：" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticStyle: { width: "100%" },
                        model: {
                          value: _vm.rand.is_sub_screen,
                          callback: function ($$v) {
                            _vm.$set(_vm.rand, "is_sub_screen", $$v)
                          },
                          expression: "rand.is_sub_screen",
                        },
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("启用"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("不启用"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.rand.is_sub_screen === 1
                      ? [
                          _c(
                            "el-col",
                            {
                              staticClass: "input-box input-name",
                              attrs: { span: 24 },
                            },
                            [_vm._v("1.显示元素（副屏显示）")]
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-row",
                                { staticClass: "mT15", attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 20, offset: 1 } },
                                    [
                                      _c(
                                        "el-row",
                                        { attrs: { gutter: 20 } },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    "true-label": 1,
                                                    "false-label": 0,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.rand
                                                        .sub_show_rand_code,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.rand,
                                                        "sub_show_rand_code",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rand.sub_show_rand_code",
                                                  },
                                                },
                                                [_vm._v(" 顺序号 ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    "true-label": 1,
                                                    "false-label": 0,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.rand.sub_show_name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.rand,
                                                        "sub_show_name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rand.sub_show_name",
                                                  },
                                                },
                                                [_vm._v(" 姓名(脱敏) ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    "true-label": 2,
                                                    "false-label": 0,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.rand.sub_show_name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.rand,
                                                        "sub_show_name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rand.sub_show_name",
                                                  },
                                                },
                                                [_vm._v(" 姓名(全显) ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    "true-label": 1,
                                                    "false-label": 0,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.rand.sub_show_tel,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.rand,
                                                        "sub_show_tel",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rand.sub_show_tel",
                                                  },
                                                },
                                                [_vm._v(" 登录手机号 ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    "true-label": 1,
                                                    "false-label": 0,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.rand
                                                        .sub_show_audit_order,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.rand,
                                                        "sub_show_audit_order",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rand.sub_show_audit_order",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.list.user_code_name
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    "true-label": 1,
                                                    "false-label": 0,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.rand.sub_show_card,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.rand,
                                                        "sub_show_card",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rand.sub_show_card",
                                                  },
                                                },
                                                [_vm._v(" 身份证号全部(脱敏) ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    "true-label": 2,
                                                    "false-label": 0,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.rand.sub_show_card,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.rand,
                                                        "sub_show_card",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rand.sub_show_card",
                                                  },
                                                },
                                                [_vm._v(" 身份证号后6位 ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    "true-label": 1,
                                                    "false-label": 0,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.rand
                                                        .sub_show_other_remark,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.rand,
                                                        "sub_show_other_remark",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rand.sub_show_other_remark",
                                                  },
                                                },
                                                [_vm._v(" 其他信息 ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "公示大屏：" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticStyle: { width: "100%" },
                        model: {
                          value: _vm.rand.is_led_screen,
                          callback: function ($$v) {
                            _vm.$set(_vm.rand, "is_led_screen", $$v)
                          },
                          expression: "rand.is_led_screen",
                        },
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("启用"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("不启用"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.rand.is_led_screen === 1
                      ? [
                          _c(
                            "el-col",
                            {
                              staticClass: "input-box input-name",
                              attrs: { span: 24 },
                            },
                            [_vm._v("1.显示元素（副屏显示）")]
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-row",
                                { staticClass: "mT15", attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 20, offset: 1 } },
                                    [
                                      _c(
                                        "el-row",
                                        { attrs: { gutter: 20 } },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    "true-label": 1,
                                                    "false-label": 0,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.rand
                                                        .led_show_rand_code,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.rand,
                                                        "led_show_rand_code",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rand.led_show_rand_code",
                                                  },
                                                },
                                                [_vm._v(" 顺序号 ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    "true-label": 1,
                                                    "false-label": 0,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.rand.led_show_name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.rand,
                                                        "led_show_name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rand.led_show_name",
                                                  },
                                                },
                                                [_vm._v(" 姓名(脱敏) ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    "true-label": 2,
                                                    "false-label": 0,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.rand.led_show_name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.rand,
                                                        "led_show_name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rand.led_show_name",
                                                  },
                                                },
                                                [_vm._v(" 姓名(全显) ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    "true-label": 1,
                                                    "false-label": 0,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.rand.led_show_tel,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.rand,
                                                        "led_show_tel",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rand.led_show_tel",
                                                  },
                                                },
                                                [_vm._v(" 登录手机号 ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    "true-label": 1,
                                                    "false-label": 0,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.rand
                                                        .led_show_audit_order,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.rand,
                                                        "led_show_audit_order",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rand.led_show_audit_order",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.list.user_code_name
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    "true-label": 1,
                                                    "false-label": 0,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.rand.led_show_card,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.rand,
                                                        "led_show_card",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rand.led_show_card",
                                                  },
                                                },
                                                [_vm._v(" 身份证号全部(脱敏) ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    "true-label": 2,
                                                    "false-label": 0,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.rand.led_show_card,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.rand,
                                                        "led_show_card",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rand.led_show_card",
                                                  },
                                                },
                                                [_vm._v(" 身份证号后6位 ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    "true-label": 1,
                                                    "false-label": 0,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.rand
                                                        .led_show_other_remark,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.rand,
                                                        "led_show_other_remark",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rand.led_show_other_remark",
                                                  },
                                                },
                                                [_vm._v(" 其他信息 ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm.list.is_rand_baas === 1
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "区块链内容" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 20 } },
                                  [
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 20 } },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 4 } },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  "true-label": 1,
                                                  "false-label": 0,
                                                },
                                                model: {
                                                  value:
                                                    _vm.rand
                                                      .baas_show_rand_code,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.rand,
                                                      "baas_show_rand_code",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "rand.baas_show_rand_code",
                                                },
                                              },
                                              [_vm._v(" 顺序号 ")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 4 } },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  "true-label": 1,
                                                  "false-label": 0,
                                                },
                                                model: {
                                                  value:
                                                    _vm.rand
                                                      .baas_show_audit_order,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.rand,
                                                      "baas_show_audit_order",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "rand.baas_show_audit_order",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.list.user_code_name
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 4 } },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  "true-label": 1,
                                                  "false-label": 0,
                                                },
                                                model: {
                                                  value:
                                                    _vm.rand.baas_show_name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.rand,
                                                      "baas_show_name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "rand.baas_show_name",
                                                },
                                              },
                                              [_vm._v(" 姓名(脱敏) ")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 4 } },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  "true-label": 1,
                                                  "false-label": 0,
                                                },
                                                model: {
                                                  value: _vm.rand.baas_show_tel,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.rand,
                                                      "baas_show_tel",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "rand.baas_show_tel",
                                                },
                                              },
                                              [_vm._v(" 手机（脱敏） ")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 4 } },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  "true-label": 2,
                                                  "false-label": 0,
                                                },
                                                model: {
                                                  value:
                                                    _vm.rand.baas_show_card,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.rand,
                                                      "baas_show_card",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "rand.baas_show_card",
                                                },
                                              },
                                              [_vm._v(" 身份证（脱敏） ")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 4 } },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  "true-label": 1,
                                                  "false-label": 0,
                                                },
                                                model: {
                                                  value:
                                                    _vm.rand
                                                      .baas_show_other_remark,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.rand,
                                                      "baas_show_other_remark",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "rand.baas_show_other_remark",
                                                },
                                              },
                                              [_vm._v(" 其他信息 ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _vm._v("编辑摇号规则 "),
      _c("span", { staticClass: "tips" }, [
        _vm._v("不会改变摇号中、已结束状态的摇号规则"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }