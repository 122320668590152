import _typeof from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/typeof.js";
import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import screenfull from '@/components/my-screen';
import { eventRandIndex } from '@/api/lottery';
import { debounce } from '@/utils/debounce';
import lotteryMixin from './lotteryMixin';
import { mapMutations, mapState } from "vuex";
import { getRoundStyle, getRowStyle } from "@/api/projectRound";
import { openJsondefault } from "@/views/editFormat/defaultSettingData";
export default {
  name: 'Lottery',
  components: {
    screenfull: screenfull
  },
  mixins: [lotteryMixin],
  data: function data() {
    return {
      styleObject: {},
      // 基本数据
      rand: {},
      // 摇号组
      group: [],
      openJson: {},
      list: {}
    };
  },
  created: function created() {
    this.getDataIndex();
    this.getAllStyle();
  },
  methods: _objectSpread(_objectSpread({}, mapMutations('editFormat', ['SET_LIST'])), {}, {
    // 去摇号
    handleStartLottery: debounce(function () {
      var data = {
        id: this.rand.id,
        group_id: this.group_id,
        round: 1
      }; // 随机摇号

      if (this.rand.type === 1) {
        // 分组摇号
        if (this.rand.type_group === 1) {
          this.$router.push({
            name: 'StartLottery',
            query: data
          });
        } else if (this.rand.type_group === 3) {
          //隐秘分组摇号
          this.$router.push({
            name: 'startLotteryHideGrou',
            query: data
          });
        } else {
          // 不分组摇号
          this.$router.push({
            name: 'StartLotteryNo',
            query: data
          });
        }
      } else {
        if (this.rand.type_group === 1) {
          // 分组排号
          this.$router.push({
            name: 'StartRowNumber',
            query: data
          });
        } else if (this.rand.type_group === 3) {//隐秘分组摇号
        } else {
          // 顺序摇号
          this.$router.push({
            name: 'StartLotteryOrder',
            query: data
          });
        }
      }
    }, 200, true),
    getAllStyle: function getAllStyle() {
      var _this = this;

      var type = this.$route.query.type;

      if (type && type == 'row') {
        getRowStyle().then(function (res) {
          var list = res.data.list;

          if (!list.open_json || _typeof(JSON.parse(list.open_json)) != 'object') {
            _this.openJson = _objectSpread({}, openJsondefault);
          } else {
            _this.openJson = JSON.parse(list.open_json);
          }
        });
      } else {
        getRoundStyle().then(function (res) {
          var list = res.data.list;

          if (!list.open_json || _typeof(JSON.parse(list.open_json)) != 'object') {
            _this.openJson = _objectSpread({}, openJsondefault);
          } else {
            _this.openJson = JSON.parse(list.open_json);
          }

          console.log(_this.openJson);
        });
      }
    },
    //    获取主页 基础数据
    getDataIndex: function getDataIndex() {
      var _this2 = this;

      eventRandIndex({
        id: this.$route.query.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data$list = res.data.list,
              group = _res$data$list.group,
              rand = _res$data$list.rand,
              group_id = _res$data$list.group_id;
          _this2.rand = rand;
          _this2.styleObject = {
            background: "url(".concat(_this2.imgUrl + rand.logo, ")100%, no-repeat"),
            backgroundSize: '100%',
            // backgroundPosition: 'center, center',
            height: '100%'
          };
          _this2.group = group;
          _this2.group_id = group_id;
          _this2.list = res.data.list; // 在缓存里加一个

          localStorage.setItem(parseInt(new Date().getTime() / 86400000).toString(), "".concat(rand.id, "-").concat(_this2.list.event.type));
        }
      });
    }
  })
};