import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.json.stringify.js";
import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import store from '@/store';
import { getProject, getToken, removeToken } from '@/utils/auth';
import utils from '@/utils/utils';
var env = process.env.VUE_APP_BUILD_TAG; // const  env ="production"
// create an axios instance

var service = axios.create({
  // 二阶段接口
  // baseURL: 'https://yxkcs1.yun-xk.com/pc', // url = base url + request url
  // baseURL: "https://kp.yun-xk.com/pc", // url = base url + request url
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout

}); // request interceptor

service.interceptors.request.use(function (config) {
  // do something before request is sent
  config.headers['Content-Type'] = 'application/json';

  if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['token'] = getToken();
    config.headers['project'] = JSON.parse(getProject()).project_id;
    config.headers['admintel'] = JSON.parse(getProject()).tel;
    config.headers['adminid'] = JSON.parse(getProject()).id;
  }

  return config;
}, function (error) {
  // do something with request error
  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response; // if the custom code is not 20000, it is judged as an error.

  if (res.status !== 200) {
    Message({
      message: res.msg || 'Error',
      type: 'error',
      duration: 5 * 1000
    }); // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;

    if (res.status === 50008 || res.status === 50012 || res.status === 50014) {
      // to re-login
      MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        confirmButtonText: 'Re-Login',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        store.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      });
    } else {}

    return Promise.reject(new Error(res.message || 'Error'));
  } else {
    if (typeof res.data === "string" && res.data && env === 'production') {
      res.data = utils.decrypt(res.data, response.config.aesKey);
    }

    return res;
  }
}, function (error) {
  console.log(error);

  if (error.response.status === 401 || error.response.status === 405) {
    // 清楚 Vuex  权限
    store.dispatch('user/resetToken').then(function () {
      location.reload();
    });
  } else if (error.response.status === 404) {
    Message({
      message: '亲，系统维护中，请稍候再试哟~',
      type: 'error',
      duration: 5 * 1000
    });
  } else {
    Message({
      message: error.response.data.msg,
      type: 'error',
      duration: 5 * 1000
    });
  }

  return Promise.reject(error);
});

var sencryptAxios = function sencryptAxios(data) {
  var encryption = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

  if (encryption && data.url.indexOf('oss.oss') < 0 && env === 'production') {
    //排除上传 oss 服务加密 不需要加密的的接口，加密仅加密正式环境
    var aesKey = utils.generateAesKey(32);

    if (data.method === 'get' || data.method === 'GET') {
      data.params = Object.assign(data.params || {}, {
        aesKey: aesKey
      });
      data.url = data.url + utils.concatParams(data.params);
      data.url = data.url.split('?')[0] + '?' + utils.parameterJSEncrypt(data.url.split('?')[1]);
      data.params = {};
    } else {
      if (data.url.indexOf('?') > -1) {
        data.url = data.url + '&aesKey=' + aesKey;
      } else {
        data.url = data.url + '?aesKey=' + aesKey;
      }

      data.url = data.url.split('?')[0] + '?' + utils.parameterJSEncrypt(data.url.split('?')[1]);
      var jmp = JSON.stringify(data.data);
      data.data = utils.parameterJSEncrypt(jmp);
    }

    data.aesKey = aesKey;
    return service(data);
  } else {
    return service(data);
  }
};

export default sencryptAxios;