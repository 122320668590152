import _toConsumableArray from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getActiveTime } from '@/api/screen';
export default {
  filters: {
    formatZero: function formatZero(num) {
      var len = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
      if (String(num).length >= len) return num;
      return (Array(len).join(0) + num).slice(-len);
    }
  },
  props: {
    // 是否显示文字
    isShowText: {
      type: Boolean,
      default: true
    },
    // 是否改变样式 大屏右侧倒计时样式
    isChangeStyle: {
      type: Boolean,
      default: false
    },
    eventId: {
      type: [Number, String],
      default: ''
    },
    roundId: {
      type: [Number, String],
      default: ''
    },
    // 是否显示 00时00分00秒
    isShowAll: {
      type: Boolean,
      default: true
    },
    projectInfo: {
      type: [Object, Array],
      default: function _default() {
        return {};
      }
    },
    ledInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    pin_ren_gou: {
      type: String,
      default: ''
    },
    project_id: {
      type: [Number, String],
      default: ''
    }
  },
  data: function data() {
    return {
      content: {},
      formatZero: function formatZero(num) {
        var len = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
        if (String(num).length >= len) return num;
        return (Array(len).join(0) + num).slice(-len);
      },
      s_timer: null,
      s_timout: null,
      batchTime: [],
      // 当前时间 时间戳
      currentDate: 0,
      // 当前时间 定时器
      currenTimeTimeout: null,
      currenTimeInterval: null,
      batchTimeTimeout: null,
      batchTimeInterval: null,
      timeout_ms: 0,
      getActiveTimeIsok: true
    };
  },
  mounted: function mounted() {
    if (this.isShowAll) {
      this.content = {
        hour: '00',
        min: '00',
        sec: '00',
        show_day: false,
        show_hour: true,
        show_min: true,
        show_sec: true
      };
    }

    var batchTimeData = {
      hour: '0',
      min: '0',
      sec: '0',
      show_day: false,
      show_hour: false,
      show_min: true,
      show_sec: true
    };
    this.$emit('batchTime', batchTimeData);
    this.getTime();
    document.addEventListener('visibilitychange', this.tabChange);
  },
  beforeDestroy: function beforeDestroy() {
    this.doClearTime();
    document.removeEventListener('visibilitychange', this.tabChange);
  },
  methods: {
    doClearTime: function doClearTime() {
      this.s_timout && clearTimeout(this.s_timout);
      this.currenTimeTimeout && clearTimeout(this.currenTimeTimeout);
      this.batchTimeTimeout && clearTimeout(this.batchTimeTimeout);
      this.s_timer && clearInterval(this.s_timer);
      this.currenTimeInterval && clearInterval(this.currenTimeInterval);
      this.batchTimeInterval && clearInterval(this.batchTimeInterval);
    },
    timer: function timer(bTime, eTime, timeout_ms, times) {
      var _this = this;

      // 一天  86400000ms
      // 一个小时 3600000ms
      // 一分钟 60000ms
      // 一秒 1000ms
      var btime = bTime;
      var etime = eTime; // 当前的时间减去服务器返回的时间，这样就可判断时间是否一致，避免时间不一致情况

      var initializeGetTime = new Date().getTime() - (times + btime);
      var isGetTime = false;
      this.timerBegin(btime, etime);
      this.s_timout && clearTimeout(this.s_timout);
      this.s_timout = setTimeout(function () {
        btime += 1000;

        _this.timerBegin(btime, etime);

        _this.s_timer && clearInterval(_this.s_timer);
        _this.s_timer = setInterval(function () {
          btime += 1000;
          var currentDateGetTime = new Date().getTime() - (times + btime); // 判断当前更新时间如果和初始化的的时间相差一秒以上就重新请求服务器获取时间 重新计算

          if ((currentDateGetTime >= initializeGetTime + 1000 || currentDateGetTime <= initializeGetTime - 1000) && !isGetTime) {
            isGetTime = true;

            _this.getTime();

            btime += currentDateGetTime - initializeGetTime;
            return;
          }

          _this.timerBegin(btime, etime);
        }, 1000);
      }, timeout_ms);
    },
    // 更新获取当前时间
    getBatchTime: function getBatchTime(bTime, timeout_ms, times, batchTime) {
      var _this2 = this;

      var btime = bTime; // 当前的时间减去服务器返回的时间，这样就可判断时间是否一致，避免时间不一致情况

      var initializeGetTime = new Date().getTime() - (times + btime); // // 是否已经请求服务器时间，避免服务器请求时间时，判断又走了一次

      var isGetTime = false; // 更新当前时间

      this.currentDate = times + btime;
      this.getNowTime(times + btime);
      this.currenTimeTimeout && clearTimeout(this.currenTimeTimeout);
      this.currenTimeTimeout = setTimeout(function () {
        btime += 1000; // 更新当前时间

        _this2.currentDate = times + btime;

        if (_this2.ledInfo.show_now_time == 1) {
          _this2.getNowTime(times + btime);
        }

        _this2.currenTimeInterval && clearInterval(_this2.currenTimeInterval);
        _this2.currenTimeInterval = setInterval(function () {
          btime += 1000;
          var currentDateGetTime = new Date().getTime() - (times + btime); // 判断当前更新时间如果和初始化的的时间相差一秒以上就重新请求服务器获取时间

          if ((currentDateGetTime >= initializeGetTime + 500 || currentDateGetTime <= initializeGetTime - 500) && !isGetTime) {
            isGetTime = true; // this.getTime();
            // btime += (currentDateGetTime - initializeGetTime);
          } // 更新当前时间


          _this2.currentDate = times + btime;

          _this2.getNowTime(times + btime);
        }, 1000);
      }, timeout_ms);
    },
    // 获取当前时间
    getNowTime: function getNowTime(time) {
      var date = new Date(time);
      var year = date.getFullYear();
      var month = date.getMonth() + 1 < 10 ? date.getMonth() + 1 : date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours();
      var minute = date.getMinutes();
      var second = date.getSeconds();
      this.times = {
        year: year,
        month: month,
        day: day,
        hour: hour,
        minute: minute,
        second: second
      };
      this.$emit('currenTime', this.times);
    },
    timerBegin: function timerBegin(btime, etime) {
      if (etime - btime >= 0) {
        var day = Math.floor((etime - btime) / 86400000);
        var hour = Math.floor((etime - btime) / 3600000 % 24);
        var min = Math.floor((etime - btime) / 60000 % 60);
        var sec = Math.floor((etime - btime) / 1000 % 60);
        var format = ''; // 判断显示内容

        if (day > 0) {
          format = {
            day: day,
            hour: hour,
            min: min,
            sec: sec,
            show_day: true,
            show_hour: true,
            show_min: true,
            show_sec: true
          };
        }

        if (day <= 0 && hour > 0) {
          format = {
            hour: hour,
            min: min,
            sec: sec,
            show_day: false,
            show_hour: true,
            show_min: true,
            show_sec: true
          };
        }

        if (day <= 0 && hour <= 0) {
          format = {
            hour: hour,
            min: min,
            sec: sec,
            show_day: false,
            show_hour: this.isShowAll,
            show_min: true,
            show_sec: true
          };
        }

        if (day <= 0 && hour <= 0 && min <= 0) {
          format = {
            hour: hour,
            min: min,
            sec: sec,
            show_day: false,
            show_hour: this.isShowAll,
            show_min: this.isShowAll,
            show_sec: true
          };
        }

        this.content = format;
      } else {
        var _format = {
          hour: '00',
          min: '00',
          sec: '00',
          show_day: false,
          show_hour: false,
          show_min: false,
          show_sec: true
        };
        this.content = _format;
        this.s_timer && clearInterval(this.s_timer);
        this.$emit('countDownOver');
      }

      this.$emit('countDownBlink', etime - btime, this.content);
    },
    fillAndAdjustArray: function fillAndAdjustArray(arr, seconds) {
      var _this3 = this;

      // 首先，根据name找到最大值和最小值
      var minName = Math.min.apply(Math, _toConsumableArray(arr.map(function (item) {
        return parseInt(item.name);
      })));
      var maxName = Math.max.apply(Math, _toConsumableArray(arr.map(function (item) {
        return parseInt(item.name);
      }))); // 创建一个新数组来存储结果

      var result = []; // 遍历从minName到maxName的每个数字

      var _loop = function _loop(i) {
        // 查找当前i是否存在于原始数组中
        var item = arr.find(function (x) {
          return parseInt(x.name) === i;
        });

        if (item) {
          // 如果存在，直接使用这个项
          result.push(item);
        } else {
          // 如果不存在，创建一个新的项
          // 假设上一个项的索引是result.length - 1
          var lastItem = result[result.length - 1];
          var newTime = lastItem ? lastItem.time + _this3.seconds : null; // 上一个time加60秒

          var newEndTime = newTime + _this3.seconds; // 新的end_time是新的time加60秒

          result.push({
            name: i.toString(),
            status: 3,
            time: newTime,
            end_time: newEndTime,
            title: "".concat(i, "\u6279\u6B21\u5DF2\u7ED3\u675F")
          });
        }
      };

      for (var i = minName; i <= maxName; i++) {
        _loop(i);
      }

      return result;
    },
    // 大屏显示 活动时间 接口
    getTime: function getTime() {
      var _this4 = this;

      if (!this.getActiveTimeIsok) {
        return;
      }

      this.getActiveTimeIsok = false;
      var params = {
        event_id: this.eventId,
        project_id: this.project_id
      }; // 接口请求前时间戳

      var apiTimeBegin = new Date().getTime();
      getActiveTime(params).then(function (res) {
        // console.log(res,'tme')
        // 时间信息
        var project_batch = res.data.project_batch;
        var eventInfo = res.data.eventInfo;
        _this4.eventInfo = eventInfo; // 服务器获取当前时间信息

        var counDtime = parseInt(res.data.times.time) * 1000;
        _this4.seconds = project_batch.interval_min * 60 + project_batch.interval_sec; //批次间隔时间
        // 批次倒计时 数据

        if (!_this4.batchTime.length && project_batch.batch) {
          res.data.batchList = _this4.fillAndAdjustArray(res.data.batchList);
          _this4.batchTime = JSON.parse(JSON.stringify(res.data.batchList));
        }

        var batchTime = JSON.parse(JSON.stringify(_this4.batchTime)); // 接口请求成功时间戳

        var apiTimeEnd = new Date().getTime(); // 接口开始请求到请求成功时间 + 服务器延时时间

        var less_ms = eventInfo.less_ms + (apiTimeEnd - apiTimeBegin); // 沉睡毫秒数 --- 去除毫秒误差，保持同步倒计时

        var timeout_ms = 0;
        var endTime = eventInfo.time;

        if (less_ms < 1000) {
          timeout_ms = less_ms;
        } else {
          // timeout_ms = 1000 - (less_ms % 1000)
          // endTime = endTime - parseInt(less_ms - 1000)
          timeout_ms = less_ms % 1000;
          endTime -= parseInt(less_ms / 1000);
          counDtime += parseInt(less_ms / 1000) * 1000;
        }

        _this4.timeout_ms = timeout_ms;

        _this4.doClearTime();

        _this4.timer(0, endTime * 1000, timeout_ms, counDtime);

        _this4.getBatchTime(0, timeout_ms, counDtime, batchTime);

        _this4.timeMolde(batchTime, timeout_ms); // 批次数组，接口加载时间


        _this4.getActiveTimeIsok = true;
      }).catch(function (res) {
        _this4.getActiveTimeIsok = true;
      });
    },
    // 倒计时模式
    timeMolde: function timeMolde(batchTime, timeout_ms) {
      // 以间隔时间为准
      if (this.ledInfo.show_time_type === 1) {
        this.countDownTime(batchTime, timeout_ms);
      } else {
        // 以持续时长为准
        this.countDownTime2(batchTime, timeout_ms);
      }
    },
    // 以间隔时间为准
    countDownTime: function countDownTime(batchTime, timeout_ms) {
      // 秒，延迟秒，当前时间，批次数组
      if (batchTime == null) return; // 当前处于哪一批次

      var current = '';

      for (var i = 0; i < batchTime.length; i++) {
        if (batchTime[i].time * 1000 <= this.currentDate && batchTime[i].end_time * 1000 >= this.currentDate) {
          current = batchTime[i].name;
        }
      }

      for (var _i = 0; _i < batchTime.length; _i++) {
        // 开始倒计时
        if (batchTime[_i].time * 1000 > this.currentDate) {
          this.$emit('uptoDate', "\u7B2C".concat(batchTime[_i].name, "\u6279\u6B21").concat(this.pin_ren_gou || '选房', "\u5F00\u59CB\u5012\u8BA1\u65F6"), current);
          var second = batchTime[_i].time * 1000 - this.currentDate;
          this.getTimeTab(0, second, timeout_ms);
          break;
        } else if (_i === batchTime.length - 1) {
          // 结束倒计时
          if (batchTime[_i].end_time * 1000 > this.currentDate) {
            this.$emit('uptoDate', "\u7B2C".concat(batchTime[_i].name, "\u6279\u6B21").concat(this.pin_ren_gou || '选房', "\u7ED3\u675F\u5012\u8BA1\u65F6"), current);

            var _second = batchTime[_i].end_time * 1000 - this.currentDate;

            this.getTimeTab(0, _second, timeout_ms);
            break;
          } // 结束
          else {
            if (batchTime.length == _i + 1) {
              this.$emit('uptoDate', "\u6279\u6B21\u5DF2\u7ED3\u675F", current);
              this.batchTimeTimeout && clearTimeout(this.batchTimeTimeout);
              this.batchTimeInterval && clearInterval(this.batchTimeInterval);
            }
          }
        }
      }
    },
    // 以持续时长为准
    countDownTime2: function countDownTime2(batchTime, timeout_ms) {
      var _this5 = this;

      // console.log(this.currentDate/1000,this.eventInfo,'cccsacasdasdasdasdas')
      // 秒，延迟秒，当前时间，批次数组
      if (batchTime == null) return; // 当前处于哪一批次

      var current = '';
      var nextbatch = ''; //下一批
      // let timeinterval =(this.currentDate / 1000)-  this.eventInfo.event_start_time  //当前时间减去活动开始时间 获取 活动开始了多久 再根据 批次间隔时间算出当前最新批次
      // let finalBatchNum = batchTime.length//最大批次号
      // let pcIndex = Math.ceil(timeinterval / this.seconds) > finalBatchNum? finalBatchNum : Math.ceil(timeinterval/this.seconds)
      // current = batchTime[pcIndex - 1].name
      // nextbatch = batchTime[pcIndex]
      // console.log(pcIndex,current,'currentcurrent')

      for (var i = 0; i < batchTime.length; i++) {
        if (batchTime[i].time * 1000 <= this.currentDate && batchTime[i].end_time * 1000 >= this.currentDate) {
          current = batchTime[i].name;
          nextbatch = batchTime[i + 1];
        }
      }

      var three = batchTime.map(function (item) {
        if (item.time * 1000 > _this5.currentDate) {
          return item.name;
        }
      }).filter(function (item) {
        return item;
      });

      if (three.length) {
        var tex = '';
        var num = 0;
        three.map(function (item) {
          num++;

          if (num <= 3) {
            tex += '第' + item + '批\xa0 \xa0';
          }
        });
        this.$emit('nextThreeBatch', tex);
      } else {
        this.$emit('nextThreeBatch', "\u65E0\u5269\u4F59\u5BA2\u6237");
        this.getnextTimeTab(0, 0, 0);
      } // 下一批时间


      if (nextbatch) {
        this.$emit('nextuptoDate', "\u8DDD\u79BB\u7B2C".concat(nextbatch.name, "\u6279\u6B21\u5F00\u59CB\u8FD8\u6709"));
        var second = nextbatch.time * 1000 - this.currentDate;
        this.getnextTimeTab(0, second, timeout_ms);
      } else {
        this.$emit('nextuptoDate', "\u5168\u90E8\u6279\u6B21\u5DF2\u5F00\u59CB");
      }

      for (var _i2 = 0; _i2 < batchTime.length; _i2++) {
        // 找到第一个未结束的批次
        if (batchTime[_i2].end_time * 1000 > this.currentDate) {
          // 开始时间大于当前时间 就用开始时间倒计时
          if (batchTime[_i2].time * 1000 > this.currentDate) {
            this.$emit('uptoDate', "\u8DDD\u79BB\u7B2C".concat(batchTime[_i2].name, "\u6279\u6B21\u5F00\u59CB\u8FD8\u6709"), current);

            var _second2 = batchTime[_i2].time * 1000 - this.currentDate;

            this.getTimeTab(0, _second2, timeout_ms);
            this.$emit('nextuptoDate', "\u8DDD\u79BB\u7B2C".concat(batchTime[_i2].name, "\u6279\u6B21\u5F00\u59CB\u8FD8\u6709"));
            this.getnextTimeTab(0, _second2, timeout_ms);
          } else {
            // 反之 用结束时间倒计时
            // ${this.pin_ren_gou || '选房'}
            this.$emit('uptoDate', "\u8DDD\u79BB\u7B2C".concat(batchTime[_i2].name, "\u6279\u6B21\u7ED3\u675F\u8FD8\u6709"), current);

            var _second3 = batchTime[_i2].end_time * 1000 - this.currentDate + 100;

            this.getTimeTab(0, _second3, timeout_ms);
          }

          break;
        } else {
          if (batchTime.length == _i2 + 1) {
            this.$emit('uptoDate', "\u6279\u6B21\u5DF2\u7ED3\u675F", current);
            this.batchTimeTimeout && clearTimeout(this.batchTimeTimeout);
            this.batchTimeInterval && clearInterval(this.batchTimeInterval);
          }
        }
      }
    },
    // 下一个批次开始倒计时
    getnextTimeTab: function getnextTimeTab(bTime, times, timeout_ms) {
      var _this6 = this;

      var btime = bTime;
      this.batchTimeInfo(times, btime, true); // 倒计时 - 1000*秒

      this.nextbatchTimeTimeout && clearTimeout(this.nextbatchTimeTimeout);
      this.nextbatchTimeTimeout = setTimeout(function () {
        btime += 1000;

        _this6.batchTimeInfo(times, btime, true);

        _this6.nextbatchTimeInterval && clearInterval(_this6.nextbatchTimeInterval);
        _this6.nextbatchTimeInterval = setInterval(function () {
          btime += 1000;

          _this6.batchTimeInfo(times, btime, true);
        }, 1000);
      }, timeout_ms);
    },
    // 开始批次倒计时
    getTimeTab: function getTimeTab(bTime, times, timeout_ms) {
      var _this7 = this;

      var btime = bTime;
      this.batchTimeInfo(times, btime); // 倒计时 - 1000*秒

      this.batchTimeTimeout && clearTimeout(this.batchTimeTimeout);
      this.batchTimeTimeout = setTimeout(function () {
        btime += 1000;

        _this7.batchTimeInfo(times, btime);

        _this7.batchTimeInterval && clearInterval(_this7.batchTimeInterval);
        _this7.batchTimeInterval = setInterval(function () {
          btime += 1000;

          _this7.batchTimeInfo(times, btime);
        }, 1000);
      }, timeout_ms);
    },
    // 批次倒计时组装时间
    batchTimeInfo: function batchTimeInfo(times, time, nextbatch) {
      if (times - time >= 0) {
        var day = Math.floor((times - time) / 86400000);
        var hour = Math.floor((times - time) / 3600000 % 24);
        var min = Math.floor((times - time) / 60000 % 60);
        var sec = Math.floor((times - time) / 1000 % 60); // 同步倒计时 接口从新请求

        if (times - time <= 0 && !this.$parent.isoverTen) {
          this.getTime();
        } // 判断显示内容


        var batchTimeData = {};

        if (day > 0) {
          batchTimeData = {
            day: day,
            hour: hour,
            min: min,
            sec: sec,
            show_day: true,
            show_hour: true,
            show_min: true,
            show_sec: true
          };
        }

        if (day <= 0 && hour > 0) {
          batchTimeData = {
            hour: hour,
            min: min,
            sec: sec,
            show_day: false,
            show_hour: true,
            show_min: true,
            show_sec: true
          };
        }

        if (day <= 0 && hour <= 0) {
          batchTimeData = {
            hour: hour,
            min: min,
            sec: sec,
            show_day: false,
            show_hour: false,
            show_min: true,
            show_sec: true
          };
        }

        if (day <= 0 && hour <= 0 && min <= 0) {
          batchTimeData = {
            hour: hour,
            min: min,
            sec: sec,
            show_day: false,
            show_hour: false,
            show_min: true,
            show_sec: true
          };
        }

        if (nextbatch) {
          this.$emit('nextbatchTime', batchTimeData);
        } else {
          this.$emit('batchTime', batchTimeData);
        }
      }
    },
    // tab切换
    tabChange: function tabChange(e) {
      if (e.target.visibilityState == 'visible') {
        // this.getTime();
        location.reload(true);
      }
    }
  }
};